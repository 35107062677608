<template>
<div>
    <v-dialog
      v-model="dialog_kifu"
      vwidth="90%"
    >
      <v-card class="kifu-dlg" vheight="90vh">

    <v-tabs
      v-model="tab"
      fixed-tabs
      background-color="blue darken-2"
      centered
      dark
    >
      <v-tabs-slider color="blue lighten-5"></v-tabs-slider>
      <v-tab href="#tab-kifu">
        将棋盤
      </v-tab>
      <v-tab href="#tab-csa">
        CSA形式
      </v-tab>
      <v-tab href="#tab-kif">
        KIF形式
      </v-tab>
      <v-tab href="#tab-ki2">
        KI2形式
      </v-tab>
      <v-tab href="#tab-sfen">
        SFEN形式
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab" class="pa-4">
      <v-tab-item value="tab-kifu"
      >
      <div
       style="display: flex;align-items: center;justify-content: center"
      >
        <KifuBoard :kif="data.kifu" v-if="data.kifu.csa" />
      </div>
      </v-tab-item>
      <v-tab-item value="tab-csa">
        <v-textarea outlined hide-details id="txa-kifu-csa"
          name="kifu-csa" label="CSA形式" ref="kif-csa"
          :value="data.kifu.csa" dense
        ></v-textarea>
        <v-btn absolute bottom small right class="mr-4 btn-kifu btn-savefile"
         @click="kifu_savefile('csa')">
          <v-icon>mdi-file-download-outline</v-icon>
          CSAファイルに保存
        </v-btn>
        <v-btn absolute bottom small right class="mr-4 btn-kifu"
         @click="kifu_clipcopy('csa')">
          <v-icon>mdi-clipboard-edit-outline</v-icon>
          クリップボードにコピー
        </v-btn>
      </v-tab-item>
      <v-tab-item value="tab-kif">
        <v-textarea outlined hide-details id="txa-kifu-kif"
          name="kifu-kif" label="KIF形式" ref="kif-kif"
          :value="data.kifu.kif" dense
        ></v-textarea>
        <v-btn absolute bottom small right class="mr-4 btn-kifu btn-savefile"
         @click="kifu_savefile('kif')">
          <v-icon>mdi-file-download-outline</v-icon>
          KIFファイルに保存
        </v-btn>
        <v-btn absolute bottom small right class="mr-4 btn-kifu"
         @click="kifu_clipcopy('kif')">
          <v-icon>mdi-clipboard-edit-outline</v-icon>
          クリップボードにコピー
        </v-btn>
      </v-tab-item>
      <v-tab-item value="tab-ki2">
        <v-textarea outlined hide-details id="txa-kifu-ki2"
          name="kifu-ki2" label="KI2形式" ref="kif-ki2"
          :value="data.kifu.ki2" dense
        ></v-textarea>
        <v-btn absolute bottom small right class="mr-4 btn-kifu btn-savefile"
         @click="kifu_savefile('ki2')">
          <v-icon>mdi-file-download-outline</v-icon>
          KI2ファイルに保存
        </v-btn>
        <v-btn absolute bottom small right class="mr-4 btn-kifu"
         @click="kifu_clipcopy('ki2')">
          <v-icon>mdi-clipboard-edit-outline</v-icon>
          クリップボードにコピー
        </v-btn>
      </v-tab-item>
      <v-tab-item value="tab-sfen">
        <v-textarea outlined hide-details id="txa-kifu-sfen"
          name="kifu-sfen" label="SFEN形式" ref="kif-sfen"
          :value="data.kifu.sfen" dense
        ></v-textarea>
        <v-btn absolute bottom small right class="mr-4 btn-kifu btn-savefile"
         @click="kifu_savefile('sfen')">
          <v-icon>mdi-file-download-outline</v-icon>
          SFENファイルに保存
        </v-btn>
        <v-btn absolute bottom small right class="mr-4 btn-kifu"
         @click="kifu_clipcopy('sfen')">
          <v-icon>mdi-clipboard-edit-outline</v-icon>
          クリップボードにコピー
        </v-btn>
      </v-tab-item>
    </v-tabs-items>

      </v-card>
    </v-dialog>

  <v-row>
    <v-col cols="12">
      <v-card v-if="data.sinfo.user">
      <v-card-title class="blue-grey lighten-4">
          <v-chip class="ma-1 py-0" color="primary">
            <v-icon left size="large">
              mdi-account
            </v-icon>
            {{ data.sinfo.user }}
          </v-chip>

          <span class="pl-3">
          {{ data.sinfo.gname }} : 
          {{ data.taikyokus.length }}件
          </span>

          <span v-if="env.VUE_APP_DEBUG">
            / {{ search }} 
          </span>

    <v-btn color="primary"
      dark small absolute right
      rounded depressed 
      @click="favorite_add(data.sinfo.user)"
      class="none-capitalize"
    >
      <v-icon>mdi-star</v-icon>
      [{{data.sinfo.user}}]をお気に入り登録
    </v-btn>

      </v-card-title>

<!--
        <v-subheader class="title text--primary">

          <v-chip class="ma-1 py-0" color="primary">
            <v-icon left size="large">
              mdi-account
            </v-icon>
            {{ data.sinfo.user }}
          </v-chip>

          {{ data.sinfo.gname }} : 
          {{ data.taikyokus.length }}件
          / {{ search }} 

        </v-subheader>
-->
<!-- ======================================= 新data -->

<v-list two-line>

  <v-list-item v-if="data.sinfo.user && data.taikyokus.length <= 0">
    <v-list-item-content>
      <v-list-item-title>
        ※該当する対局データが存在しないか、取得できませんでした。
      </v-list-item-title>
    </v-list-item-content>
  </v-list-item>

  <template v-for="(kyoku,n) in data.taikyokus">
    <v-divider v-if="n > 0" :key="`divider-${n}`" inset></v-divider>
    <v-list-item :key="n"
     :class="kyoku.kekka=='勝'?'white':'blue-grey lighten-5'">
      <v-list-item-avatar
       :color="kyoku.kekka=='勝'?'primary':'grey darken-1'"
       size="48" style="justify-content: center;">
        <span class="white--text headline">{{kyoku.kekka}}</span>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title>
          {{ n + 1 }}: 
          <v-chip class="ma-1 py-0"
           @click="user_select(kyoku.self.user)"
           color="primary" outlined pill small>
            <v-icon left size="large">mdi-account</v-icon>
            {{kyoku.self.user}}
            <span class="level">( {{kyoku.self.rank}} )</span>
          </v-chip> の
          <img :src="kyoku.self.first ? res.icon_black : res.icon_white" width="15"/>
          {{kyoku.self.teban}}番

          <span class="versus">VS</span>

          <v-chip class="ma-1 py-0"
           @click="user_select(kyoku.other.user)"
           color="grey darken-1" outlined pill small>
            <v-icon left size="large">mdi-account</v-icon>
            {{kyoku.other.user}}
            <span class="level">( {{kyoku.other.rank}} )</span>
          </v-chip> の
          <img :src="kyoku.other.first ? res.icon_black : res.icon_white" width="15"/>
          {{kyoku.other.teban}}番
                        
        </v-list-item-title>
        <v-list-item-subtitle>
            対局日時：{{moment(kyoku.begin_ts,res.MOMENT_DTTM_FMT)}}
            <span v-if="kyoku.steps"> / 手数：{{kyoku.steps}}手</span>
            <span v-if="kyoku.gsenkei"> / 戦型：{{kyoku.gsenkei}}</span>
        </v-list-item-subtitle>
      </v-list-item-content>

      <v-list-item-action>
        <v-btn @click.stop="kifu_show(kyoku)" small class="mb-1"
         :color="kyoku.kifu_id ? 'blue lighten-4' : 'white'"
        >棋譜確認</v-btn>
        <v-btn @click.stop="kifu_piyo(kyoku)" small class="mb-1"
         :color="kyoku.kifu_id ? 'blue lighten-4' : 'white'"
        >ぴよ将棋</v-btn>
      </v-list-item-action>

    </v-list-item>
  </template>
</v-list>
<!-- ======================================= /新data -->
      </v-card>
    </v-col>
  </v-row>

</div>
</template>

<style scoped>
.v-divider {
  margin: 0;
}
span.versus {
  margin-right: 0.5rem;
  margin-left: 0.5rem;
  font-size :0.7rem;
}
span.level {
  font-size :0.7rem;
  margin-left: 3px;
}
.none-capitalize {
  text-transform: none !important;
}
.btn-kifu {
  justify-content: left;
  min-width: 200px !important;
}
.btn-savefile {
  bottom: 60px;
}
</style>

<style >
.kifu-dlg .v-textarea textarea {
  height: 70vh;
  font-family: monospace;
  font-size: 0.8rem;
  line-height: 1.25rem;
}
.ShogiPlayerGround {
  width: 65vmin;
  height: 75vmin;
  top: -0.5rem;
}
@media screen and (orientation: landscape) {
   /* 横向きの場合のスタイル */
}
@media screen and (orientation: portrait) {
  .ShogiPlayerGround {
    min-height: 75vh;
    min-width: 45vh;
  }
}

/*
.ShogiPlayer {
  width: 65vmin;
}
.v-tabs-items {
  height: 85vh;
}
*/
</style>

<script>
// @ is an alias to /src
// yarn add moment
import moment from 'moment';
import { Kifu } from '@/mixins/kifu/kifu.js';
import AppUtil from '@/mixins/AppUtil';
import AppCommon from '@/mixins/AppCommon';
import KifuBoard from '@/components/KifuBoard.vue'

export default {
  name: 'Home',
  mixins: [AppUtil,AppCommon],
  components: {
    KifuBoard
  },
  data: () => ({
    env: process.env,
    search: {
      ui: 'fkz4',
      gt: '',
    },
    data: {
      kifu: {},
      sinfo: {},
      taikyokus: [],
    },
    tab: null,
    dialog_kifu: false,
    res: {
      MOMENT_DT_FMT: 'YYYY年MM月DD日(ddd)',
      MOMENT_DTTM_FMT: 'YYYY年MM月DD日(ddd) HH:mm:SS',
      icon_black: require('@/assets/img/shogi_koma_black.svg'),
      icon_white: require('@/assets/img/shogi_koma_white.svg'),
    },
  }),
  created () {
    this.init()
  },
  beforeRouteUpdate (to, from, next) {
    next()
    this.init()
  },
  methods: {
    init: function () {
      // this.task_id = this.$route.params.id
      // setTimeout(function(){
      //   this.data_wait()
      // }.bind(this),10)
      moment.locale('ja');
    },
    moment: function (date, fmt) {
      if (!fmt) fmt = 'YYYY/MM/DD HH:mm:SS'
      return moment(date).format(fmt)
    },
    kifu_savefile: function(ktype) {
      const blob = new Blob([this.data.kifu[ktype]], {type: 'text/plain'})
      const url = URL.createObjectURL(blob)
      const a = document.createElement('a')
      document.body.appendChild(a)
      a.download = 'shogi' + process.env.VUE_APP_MODE
       + '-kifu-' + this.data.kifu.kid + '.' + ktype
      a.href = url
      a.click()
      a.remove()
      URL.revokeObjectURL(url)
    },
    kifu_clipcopy: function(ktype) {
      document.getElementById('txa-kifu-' + ktype).select()
      // this.$refs['kif-' + ktype].select()
      document.execCommand('copy')
      const msg = 'クリップボードに棋譜データをコピーしました'
      this.$router.app.$emit(
        'notify',{mode:'start',msg: msg})
    },
    kifu_search: function(search) {
      const self = this
      self.search = search
      const data = { ... self.search }
      this.API_searchTaikyoku(data,function(rs){
        let sinfo = null
        if (rs.result != 'success') {
          const msg = '' + rs.error.join("\n")
          self.$router.app.$emit(
            'warning',{mode:'start',msg: msg})
          return
        }
        else {
          sinfo = {...rs.meta}
          self.data.sinfo = sinfo //rs.meta
        }

        //self.data.taikyokus2 = rs.datas2
        // self.data.taikyokus2 = rs.datas
        self.dialog = false

        var us = rs.meta.user
        var ds = []
        rs.datas.forEach(function(data) {
          // var us = data.searcher
          var nd = {
            sinfo: sinfo,
            kekka: 
              ((us == data.white_user && data.winner == 'W') ||
              (us == data.black_user && data.winner == 'B')) ? '勝' : '負',
            black : {
              teban: '先手',
              first: true,
              user: data.black_user,
              rank: data.black_rank,
              self: (us == data.black_user),
              result: data.winner == 'B' ? '勝' : '負',
            },
            white : {
              teban: '後手',
              first: false,
              user: data.white_user,
              rank: data.white_rank,
              self: (us == data.white_user),
              result: data.winner == 'W' ? '勝' : '負',
            },
            ...data
          }
          nd.self = (us == data.white_user) ? nd.white : nd.black;
          nd.other = (us == data.white_user) ? nd.black : nd.white;
          ds.push(nd)
          console.log(nd)
        })
        self.data.taikyokus = ds
        //console.log(ds)
        //alert("self.data.taikyokus2")

        if (rs.result == 'success') {
          const msg = '' + rs.datas.length + '件の対局データを取得'
          self.$router.app.$emit(
            'notify',{mode:'start',msg: msg})
          self.$emit('search-find',
            {user: rs.meta.user, gtype: rs.meta.gtype}
          )
        }

      })
    },
    favorite_add: function(userid) {
      this.$emit('favorite-add', userid)
    },
    user_select: function(userid) {
      const self = this
      self.$emit('search-change', {...self.search, ui: userid})
    },
    // ぴよ将棋で棋譜をひらく
    kifu_piyo: function(kyoku) {
      const self = this
      self.kifu_get(kyoku, function(kifu) {
        self.data.kifu = kifu
        self.dialog_kifu = false
        // kifu.sfen = sfen_txt
        const kinfo = kifu.kifinfo
        const qenc = encodeURIComponent

        let piyourl = 'https://www.studiok-i.net/kifu/'
        const b = ''+kinfo.black_user+'('+kinfo.black_rank+')'
        const w = ''+kinfo.white_user+'('+kinfo.white_rank+')'
        const g = ''+process.env.VUE_APP_TITLE+'('+kyoku.sinfo.gname+') '
          + self.moment(kyoku.begin_ts, self.res.MOMENT_DTTM_FMT)
        // sfen=position sfen
        piyourl += '?sfen=' + qenc(kifu.sfen)
        // sente_name=Lain_77 五段
        piyourl += '&sente_name=' + qenc(b)
        // gote_name=piropirosan 二段
        piyourl += '&gote_name=' + qenc(w)
        // game_name=
        piyourl += '&game_name=' + qenc(g)
        window.open(piyourl, '_blank')
      })
    },
    kifu_show: function(kyoku) {
      const self = this
      self.kifu_get(kyoku, function(kifu) {
        self.data.kifu = kifu
        self.dialog_kifu = true
      })
    },
    // 棋譜取得共通処理
    //kifu_get: function(kif, callback) {
    kifu_get: function(kyoku, callback) {
      // キャッシュがある場合は利用
      if (kyoku.cache_kifu) {
        if (callback) callback(kyoku.cache_kifu)
        return
      }

      const kif = kyoku.raw_kid
      const self = this
      // alert('kif:' + kif)
      const data = { kf: kif }
      this.API_getKifu(data,function(rs){
        if (rs.result != 'success') {
          const msg = '' + rs.error.join("\n")
          self.$router.app.$emit(
            'warning',{mode:'start',msg: msg})
          return
        }
        var kifu = rs.meta
        kifu.csa = rs.data

        var vkifu = Kifu(rs.data, 'csa');
        var kif_txt = vkifu.output('kif');
        var ki2_txt = vkifu.output('ki2');
        var sfen_txt = vkifu.output('sfen');

        var wzhd = '# ---- '+process.env.VUE_APP_TITLE+' 棋譜ファイル ----';
        var jshd = '# --- generated by jsShogiKifu ---';
        kif_txt = kif_txt.replace(jshd,wzhd);
        ki2_txt = ki2_txt.replace(jshd,wzhd);
        kifu.kif = kif_txt
        kifu.ki2 = ki2_txt
        kifu.sfen = sfen_txt

        kyoku.kifu_id = kifu.kifinfo.kifu_id
        kyoku.cache_kifu = kifu
        if (callback) callback(kifu)
      })
    },
    // 棋譜取得API呼び出し
    // ------------------------------
    API_getKifu: function (data, callback) {
      let path = window.location.pathname
      //alert(path)
      //if ('/' != path.slice(-1)) path = path + '/'
      if ('/' == path.slice(-1)) path = path.slice(0,-1)
      let apiurl = '' + path + '/api/kifu.php'
      if (path.length <= 0)
         apiurl = '/shogi' + process.env.VUE_APP_MODE + '/api/kifu.php'

      this.$router.app.$emit(
        'loading',{mode:'start',msg:'棋譜を取得中・・・'})
      this.$axios.post(
        apiurl, data, { headers: this.ApiAuthHeaders() }
      ).then(response => {
        const rs = response.data
        console.log('response.data', rs)
        callback(rs)
      }).catch(err => {
        if (!err.response)
          console.log('err', err)
        const rs = err.response.data
        console.log('err', rs)
        alert(JSON.stringify(rs))
      }).finally(_ => {
        this.loading = false
        this.$router.app.$emit(
          'loading',{mode:'stop'})
      })
    },
    // 対局一覧取得登録API呼び出し
    // ------------------------------
    API_searchTaikyoku: function (data, callback) {
      let path = window.location.pathname
      //alert(path)
      //if ('/' != path.slice(-1)) path = path + '/'
      if ('/' == path.slice(-1)) path = path.slice(0,-1)
      let apiurl = '' + path + '/api/taikyoku.php'
      if (path.length <= 0)
         apiurl = '/shogi' + process.env.VUE_APP_MODE + '/api/taikyoku.php'

      this.loading = true
      this.$router.app.$emit(
        'loading',{mode:'start',msg:'対局リストを検索中・・・'})
      this.$axios.post(
        apiurl, data, { headers: this.ApiAuthHeaders() }
      ).then(response => {
        const rs = response.data
        console.log('response.data', rs)
        callback(rs)
      }).catch(err => {
        if (!err.response)
          console.log('err', err)
        const rs = err.response.data
        console.log('err', rs)
        alert(JSON.stringify(rs))
      }).finally(_ => {
        this.loading = false
        this.$router.app.$emit(
          'loading',{mode:'stop'})
      })
    },
  },
}
</script>
